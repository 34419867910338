function init() {
	// Remove archive description from specific pages
	const pages = document.querySelectorAll(".search");
	if (pages) {
		const pagesRemoveArchiveDescription = removeArchiveDescription(pages);
	}
}

function removeArchiveDescription(pages) {
	pages.forEach((el) => {
		const archiveDescriptionEl = el.querySelector(
			".ast-archive-description"
		);

		archiveDescriptionEl.remove();
	});
}

export default { init };
