// import ada from "./modules/ada";
import astra from "./modules/astra";
// import contact from "./modules/modals/contact";
// import video from "./modules/modals/video";
import pagination from "./modules/pagination";
import sliders from "./modules/sliders";
// import test from "./modules/test";
import utils from "./modules/utils";

// Utils
// Format phone numbers automatically
// utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
// utils.e10kRemoveEmptyPTags(
// 	document.querySelectorAll(
// 		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
// 	)
// );

// Video fade
utils.e10kVideoFadeIn(document.querySelector(".background-video"));

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
astra.init();

// Pagination
// pagination.init();

// Modals
// contact.init();
// video.init();

// Sliders
sliders.init();

// test.log();
